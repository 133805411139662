<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <span class="d-none d-sm-inline-block">- All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">{{ appName }}</span>
  </p>
</template>

<script>
import { $themeConfig } from '@themeConfig'

export default {
  setup() {
    // App Name
    const { appName } = $themeConfig.app

    return {
      appName
    }
  }
}
</script>
